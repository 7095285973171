import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import './Chatbox.css';
import CONFIG from '../config';


const Chatbox = () => {
  const [messages, setMessages] = useState(() => {
    // Load conversation from sessionStorage if it exists
    const savedMessages = sessionStorage.getItem('conversation');
    if (savedMessages) {
      return JSON.parse(savedMessages)
    }
    else {
      return [{ role: "assistant", content: "Welcome, Bienvenido, Bienvenue, Bienvenuto, Willkommen! 🍣" }]
    }    
  });

  const { BASE_URL } = CONFIG;
  const [userInput, setUserInput] = useState("");
  const inputRef = useRef(null);  
  const [isLoading, setIsLoading] = useState(false);
  const [typingMessage, setTypingMessage] = useState(""); // For typing effect
  const messagesEndRef = useRef(null); // For auto-scrolling

  useEffect(() => {
    // Save conversation to sessionStorage on every update
    sessionStorage.setItem('conversation', JSON.stringify(messages));
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    const chatboxMessages = document.querySelector('.chatbox-messages'); // Target the chatbox container
    if (chatboxMessages) {
      chatboxMessages.scrollTo({
        top: chatboxMessages.scrollHeight,
        behavior: 'smooth', // Smooth scrolling
      });
    }
  };

 useEffect(() => {
  const timeout = setTimeout(() => {
    scrollToBottom();
  }, 100); // Adjust the delay as needed (100ms is a good starting point)

  return () => clearTimeout(timeout); // Cleanup timeout on unmount
}, []);

  const simulateTypingEffect = (fullMessage) => {
    // Threshold for "long" message
  const THRESHOLD = 500;
  
  // Base delay for normal/lower message lengths
  const BASE_DELAY = 10; 
  // Delay for long messages (above THRESHOLD number of characters)
  const LONG_MESSAGE_DELAY = 1;
  
  
  // If the message is longer than threshold, make the typing speed 10x faster
  const isLongMessage = fullMessage.length > THRESHOLD;
  const delay = isLongMessage ? LONG_MESSAGE_DELAY : BASE_DELAY;

    setTypingMessage(""); // Reset typing message
    let currentCharIndex = 0;
    let typedMessage = ""; // Local variable to track the typed message

    const typeCharacter = () => {
        if (currentCharIndex < fullMessage.length) {
            typedMessage += fullMessage[currentCharIndex]; // Append the next character
            setTypingMessage(typedMessage); // Update state with the local variable
            currentCharIndex++;

            // Trigger auto-scroll after updating the typing message
            scrollToBottom();

            setTimeout(typeCharacter, delay); // Schedule the next character
        } else {
            // Once typing is done, add the full message to messages and clear typingMessage
            setMessages((prevMessages) => {
                const updatedMessages = [
                    ...prevMessages,
                    { role: "assistant", content: fullMessage },
                ];

                // Save conversation to backend
                try {
                    const sessionId = sessionStorage.getItem('sessionId');
                    if (sessionId) {
                        const payload = JSON.stringify({ sessionId, conversation: updatedMessages });
                        fetch(`${BASE_URL}save_conversation`, {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: payload,
                        })
                        .then((response) => {
                            if (response.ok) {
                                console.log("Conversation saved successfully after bot response.");
                            } else {
                                console.error("Error saving conversation: ", response.statusText);
                            }
                        })
                        .catch((error) => {
                            console.error("Error in fetch call: ", error);
                        });
                    }
                } catch (error) {
                    console.error("Error saving conversation after bot response:", error);
                }

                return updatedMessages;
            });
            setTypingMessage(""); // Clear typing message
            setIsLoading(false); // End typing phase
        }
    };

    typeCharacter();
  };

  useEffect(() => {
    if (!isLoading && inputRef.current) {
      // Now that isLoading is false and the component re-rendered,
      // the input is enabled, so focusing will show the cursor.
      inputRef.current.focus();
    }
  }, [isLoading]);

  // Keep track of size of sessionStorage variable
  useEffect(() => {
    const dataSize = new Blob([JSON.stringify(sessionStorage)]).size;
    console.log(`SessionStorage size: ${dataSize} bytes`);
  }, [messages]);


  const handleSend = async () => {
    if (!userInput.trim()) return;

    // Add user message to chat
    const newMessages = [...messages, { role: 'user', content: userInput }];
    setMessages(newMessages);

    setIsLoading(true);

    try {
      // Send user message to backend
      const response = await fetch(`${BASE_URL}get_chatbot_response`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ conversation: newMessages }),
      });

      if (!response.ok) throw new Error('Failed to fetch chatbot response');

      const data = await response.json();

      // Simulate typing effect with the bot's response
      simulateTypingEffect(data.message);
    } catch (error) {
      console.error("Error fetching chatbot response:", error);
      setMessages([
        ...newMessages,
        { role: 'assistant', content: "Oops! Something went wrong. Please try again." },
      ]);
      setIsLoading(false);
    }

    // Clear input field
    setUserInput("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent newline if it's a textarea
      handleSend();
    }
  };

  // Ensure a sessionId exists
  useEffect(() => {
    if (!sessionStorage.getItem('sessionId')) {
      const newSessionId = `session-${Date.now()}`;
      sessionStorage.setItem('sessionId', newSessionId);
      console.log(`Generated new sessionId: ${newSessionId}`);
    }
  }, []);

  function LinkRenderer({ href, children, ...props }) {
    // Step A: Check if it's an Amazon link (you already had logic to open it in a new tab)
    const isAmazonLink = href.includes("amazon.com");

    // Step B: Fix malformed `#/` links
    // If it's not an Amazon link and we see `#/` in the href, replace with `#`
    if (!isAmazonLink && href.includes("#/")) {      
      href = href.replace("#/", "#");
    }

    // Step C: Convert links to sushiaya.com into relative links
    // This handles https://sushiaya.com or https://www.sushiaya.com plus optional port
    const domainRegex = /^https?:\/\/(www\.)?(sushiaya\.com)(:\d+)?/;
    let finalHref = href;
    let isExternal = true;  // assume external by default

    if (domainRegex.test(finalHref)) {
      // If the domain is sushiaya.com, treat as internal
      const urlObj = new URL(finalHref);
      finalHref = urlObj.pathname + urlObj.search + urlObj.hash;
      isExternal = false;
    }

    // Step D: Decide how to open the link
    // - Amazon or truly external => new tab
    // - Sushiaya internal => same tab
    const target = (isAmazonLink || isExternal) ? "_blank" : "_self";
    const rel = (target === "_blank") ? "noopener noreferrer" : undefined;

    return (
      <a href={finalHref} target={target} rel={rel} {...props}>
        {children}
      </a>
    );
  }
  

  return (
    <div className="chatbox">
      <div className="chatbox-messages">
      {messages.map((msg, index) => (
        <div key={index} className={`message ${msg.role}`}>
          {msg.role === 'assistant' ? (
            <span>
              <strong>AYA:</strong>{" "}
              <ReactMarkdown
                components={{
                  p: ({ node, children }) => <span>{children}</span>, // Inline rendering for paragraphs
                  a: LinkRenderer
                }}
              >
                {msg.content}
              </ReactMarkdown>
            </span>
          ) : (
            <span>
              {msg.content}
            </span>
          )}
        </div>
      ))}
      {typingMessage && (
        <div className="message assistant typing-message">
          <strong style={{ display: "inline" }}>AYA:</strong>{" "}
          <ReactMarkdown
            components={{
              p: ({ node, children }) => <span>{children}</span>, // Ensure ReactMarkdown renders inline
            }}
          >
            {typingMessage}
          </ReactMarkdown>
        </div>
      )}
        <div ref={messagesEndRef} />
      </div>
      <div className="chatbox-input">
        <input
          type="text"
          ref={inputRef}
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyDown={handleKeyDown} // Detect Enter key
          placeholder="Type your message here..."
          disabled={isLoading}
        />
        <button onClick={handleSend} disabled={isLoading}>
          {isLoading ? 'Sending...' : 'Send'}
        </button>
      </div>
    </div>
  );
};

export default Chatbox;